import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Button,
  Table,
  message,
  Popconfirm,
  Spin,
  Select,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getMembers, deleteMember, getShops } from "../../store/actions";
import { successDeleteMessage } from "../../util/messages";
import { Positions } from "../../util/positions";
import { ExportToExcel } from "../excel/ExportToExcel";
import Search from "antd/lib/input/Search";

const { Title } = Typography;
const { Option } = Select;

const ShowMembers = ({
  status,
  error,
  auth,
  member,
  shop,
  getMembers,
  deleteMember,
  getShops,
}) => {
  const [filterMembers, setFilterMembers] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const navigate = useNavigate();

  const filterData = () => {
    if (filterInput === "") return filterMembers;

    let filterM = filterMembers.filter((member) =>
      member.name.includes(filterInput)
    );
    if (filterM.length > 0) {
      return filterM;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getMembers();
      await getShops();
    };
    fetchData();

    return () => fetchData();
  }, [getMembers, getShops]);

  useEffect(() => {
    setFilterMembers(member.members);
  }, [member.members]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(successDeleteMessage);
    }

    return () => status.success;
  }, [status.success]);

  const handleDelete = async (id) => {
    await deleteMember(id);
  };

  const handleFilter = (value) => {
    if (value === undefined) {
      setFilterMembers(member.members);
    } else {
      const filterByShop = member.members.filter(
        (member) => Number(member.shop_id) === value
      );
      setFilterMembers(filterByShop);
    }
  };

  const { position } = auth.user;

  let columns = [];

  if (position === Positions.MANAGER) {
    columns = [
      {
        title: "မန်ဘာကုတ်",
        dataIndex: "code",
      },
      {
        title: "အမည်",
        dataIndex: "name",
      },
      {
        title: "ဖုန်းနံပါတ်",
        dataIndex: "phone",
      },
      {
        title: "နေရပ်လိပ်စာ",
        dataIndex: "address",
      },
      {
        title: "ဆိုင်",
        dataIndex: "shop",
        render: (shop) => shop.name,
      },
      {
        title: "ပွိုင့်",
        dataIndex: "points",
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (id) => (
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => navigate(`/admin/edit-members/${id}`)}
            >
              Edit
            </Button>
            <Popconfirm
              title="ဖျက်မှာ သေချာပြီလား"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(id)}
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
  } else {
    columns = [
      {
        title: "မန်ဘာကုတ်",
        dataIndex: "code",
      },
      {
        title: "အမည်",
        dataIndex: "name",
      },
      {
        title: "ဖုန်းနံပါတ်",
        dataIndex: "phone",
      },
      {
        title: "နေရပ်လိပ်စာ",
        dataIndex: "address",
      },
      {
        title: "ဆိုင်",
        dataIndex: "shop",
        render: (shop) => shop.name,
      },
      {
        title: "ပွိုင့်",
        dataIndex: "points",
      },
    ];
  }

  const exportMember = filterMembers.map((member) => {
    return {
      id: member.id,
      code: member.code,
      name: member.name,
      phone: member.phone,
      address: member.address,
      shop: member.shop.name,
    };
  });

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 10 }}>
              <Title level={3}>မန်ဘာစာရင်း</Title>
            </Col>
            <Col xl={{ span: 5 }}>
              <Search
                placeholder="Member Name"
                allowClear
                style={{ borderRadius: "10px" }}
                onSearch={setFilterInput}
              />
            </Col>
            <Col xl={{ span: 6 }}>
              {(position === Positions.MANAGER ||
                position === Positions.CASHIER) && (
                <Button
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white-color)",
                    borderRadius: "5px",
                  }}
                  size="middle"
                  onClick={() => navigate("/admin/create-members")}
                >
                  <PlusSquareOutlined />
                  New
                </Button>
              )}
              {position === Positions.OWNER && (
                <Select
                  showSearch
                  placeholder="ကျေးဇူးပြု၍ ဆိုင်အမည်ရွေးပါ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  size="large"
                  style={{ borderRadius: "10px" }}
                  onChange={(value) => handleFilter(value)}
                >
                  {shop.shops.map((shop) => (
                    <Option value={shop.id} key={shop.id}>
                      {shop.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col xl={{ span: 3 }}>
              <ExportToExcel apiData={exportMember} fileName="မန်ဘာများ" />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            dataSource={filterData()}
            pagination={{ defaultPageSize: 10 }}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  auth: store.auth,
  shop: store.shop,
  member: store.member,
});

export default connect(mapStateToProps, { getMembers, deleteMember, getShops })(
  ShowMembers
);
