import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  message,
  Spin,
  Select,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { connect } from "react-redux";
import { getOutOfStocks, getShops } from "../../store/actions";
import { ExportToExcel } from "../excel/ExportToExcel";
import { Positions } from "../../util/positions";

const { Title } = Typography;
const { Option } = Select;

const ShowStocks = ({
  status,
  error,
  auth,
  stock,
  shop,
  getOutOfStocks,
  getShops,
}) => {
  const [filterOutOfStocks, setFilterOutOfStocks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getOutOfStocks();
      await getShops();
    };

    fetchData();

    return () => fetchData();
  }, [getOutOfStocks, getShops]);

  useEffect(() => {
    setFilterOutOfStocks(stock.outOfStocks);
  }, [stock.outOfStocks]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  const handleFilter = (value) => {
    if (value === undefined) {
      setFilterOutOfStocks(stock.outOfStocks);
    } else {
      const filterByShop = stock.outOfStocks.filter(
        (outOfStock) => Number(outOfStock.shop_id) === value
      );
      setFilterOutOfStocks(filterByShop);
    }
  };

  const { position } = auth.user;

  const columns = [
    {
      title: "ပစ္စည်းပုံ",
      dataIndex: "item",
      render: (item) => (
        <img src={item.image} alt="ပစ္စည်းပုံ" width={100} height={100} />
      ),
    },
    {
      title: "ပစ္စည်းကုတ်",
      dataIndex: "item",
      render: (item) => item.code,
    },
    {
      title: "ပစ္စည်းအမည်",
      dataIndex: "item",
      render: (item) => item.item_name.name,
    },
    {
      title: "ပစ္စည်းဆိုဒ်",
      dataIndex: "item",
      render: (item) => item.size,
    },
    {
      title: "ကာလာ",
      dataIndex: "item",
      render: (item) => item.color,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
    },
    {
      title: "ဝယ်ဈေး",
      dataIndex: "item",
      render: (item) => item.buy_price,
    },
    {
      title: "ရောင်းဈေး",
      dataIndex: "item",
      render: (item) => item.sale_price,
    },
    {
      title: "ဆိုင်",
      dataIndex: "shop",
      render: (shop) => shop.name,
    },
  ];

  const exportOutofStock = filterOutOfStocks.map((stock) => {
    return {
      id: stock.id,
      shop: stock.shop.name,
      code: stock.item.code,
      name: stock.item.item_name.name,
      size: stock.item.size,
      color: stock.item.color,
      buy_price: stock.item.buy_price,
      sale_price: stock.item.sale_price,
      quantity: stock.quantity,
      image: stock.item.image,
    };
  });

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 15 }}>
              <Title level={3}>Out of Stock စာရင်း</Title>
            </Col>
            <Col xl={{ span: 6 }}>
              {position === Positions.OWNER && (
                <Select
                  showSearch
                  placeholder="ကျေးဇူးပြု၍ ဆိုင်အမည်ရွေးပါ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  size="large"
                  style={{ borderRadius: "10px" }}
                  onChange={(value) => handleFilter(value)}
                >
                  {shop.shops.map((shop) => (
                    <Option value={shop.id} key={shop.id}>
                      {shop.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col xl={{ span: 3 }}>
              <ExportToExcel
                apiData={exportOutofStock}
                fileName="လက်ကျန်ပျက်စတော့များ"
              />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={filterOutOfStocks}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  auth: store.auth,
  stock: store.stock,
  shop: store.shop,
});

export default connect(mapStateToProps, { getOutOfStocks, getShops })(ShowStocks);
