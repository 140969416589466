import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Table,
  message,
  Spin,
  Select,
  Button,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getStocks, getShops } from "../../store/actions";
import { ExportToExcel } from "../excel/ExportToExcel";
import { Positions } from "../../util/positions";
import { useNavigate } from "react-router-dom";
import Search from "antd/lib/input/Search";

const { Title } = Typography;
const { Option } = Select;

const ShowStocks = ({
  status,
  error,
  auth,
  stock,
  shop,
  getStocks,
  getShops,
}) => {
  const [filterStocks, setFilterStocks] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const navigate = useNavigate();

  const filterData = () => {
    if (filterInput === "") return filterStocks;

    let filterS = filterStocks.filter((stock) =>
      stock.item.code.includes(filterInput)
    );
    if (filterS.length > 0) {
      return filterS;
    }
    filterS = filterStocks.filter((stock) =>
      stock.item.item_name?.name.includes(filterInput)
    );
    if (filterS.length > 0) {
      return filterS;
    }
    filterS = filterStocks.filter((stock) =>
      stock.item.size.includes(filterInput)
    );
    if (filterS.length > 0) {
      return filterS;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getStocks();
      await getShops();
    };
    fetchData();

    return () => fetchData();
  }, [getStocks, getShops]);

  useEffect(() => {
    setFilterStocks(stock.stocks);
  }, [stock.stocks]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  const handleFilter = (value) => {
    if (value === undefined) {
      setFilterStocks(stock.stocks);
    } else {
      const filterByShop = stock.stocks.filter(
        (stock) => Number(stock.shop_id) === value
      );
      setFilterStocks(filterByShop);
    }
  };

  const { position } = auth.user;

  const columns = [
    {
      title: "ပစ္စည်းပုံ",
      dataIndex: "item",
      render: (item) => (
        <img src={item.image} alt="ပစ္စည်းပုံ" width={100} height={100} />
      ),
    },
    {
      title: "ပစ္စည်းကုတ်",
      dataIndex: "item",
      render: (item) => item.code,
    },
    {
      title: "ပစ္စည်းအမည်",
      dataIndex: "item",
      render: (item) => item.item_name?.name,
    },
    {
      title: "ပစ္စည်းဆိုဒ်",
      dataIndex: "item",
      render: (item) => item.size,
    },
    {
      title: "ကာလာ",
      dataIndex: "item",
      render: (item) => item.color,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
    },
    {
      title: "ဝယ်ဈေး",
      dataIndex: "item",
      render: (item) => item.buy_price,
    },
    {
      title: "ရောင်းဈေး",
      dataIndex: "item",
      render: (item) => item.sale_price,
    },
    {
      title: "ဆိုင်",
      dataIndex: "shop",
      render: (shop) => shop?.name,
    },
  ];

  const exportStock = filterStocks.map((stock) => {
    return {
      id: stock.id,
      shop: stock.shop?.name,
      code: stock.item.code,
      name: stock.item.item_name?.name,
      size: stock.item.size,
      color: stock.item.color,
      buy_price: stock.item.buy_price,
      sale_price: stock.item.sale_price,
      quantity: stock.quantity,
      image: stock.item.image,
    };
  });

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 10 }}>
              <Title level={3}>Stock စာရင်း</Title>
            </Col>
            <Col xl={{ span: 5 }}>
              <Search
                placeholder="Name, Code, Size"
                allowClear
                style={{ borderRadius: "10px" }}
                onSearch={setFilterInput}
              />
            </Col>
            <Col xl={{ span: 6 }}>
              {position === Positions.OWNER && (
                <Select
                  showSearch
                  placeholder="ကျေးဇူးပြု၍ ဆိုင်အမည်ရွေးပါ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  size="large"
                  style={{ borderRadius: "10px" }}
                  onChange={(value) => handleFilter(value)}
                >
                  {shop.shops.map((shop) => (
                    <Option value={shop.id} key={shop.id}>
                      {shop?.name}
                    </Option>
                  ))}
                </Select>
              )}
              {(position === Positions.MANAGER ||
                position === Positions.SaleAgent) && (
                <Button
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white-color)",
                    borderRadius: "5px",
                  }}
                  size="middle"
                  onClick={() => navigate("/admin/create-stocks")}
                >
                  <PlusSquareOutlined />
                  New
                </Button>
              )}
            </Col>
            <Col xl={{ span: 3 }}>
              <ExportToExcel apiData={exportStock} fileName="စတော့များ" />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={filterData()}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  auth: store.auth,
  stock: store.stock,
  shop: store.shop,
});

export default connect(mapStateToProps, { getStocks, getShops })(ShowStocks);
