import React, { useState, useEffect, useRef } from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// ant design styles
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  Table,
  Divider,
  message,
  Spin,
  Image,
} from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { connect } from 'react-redux'
import { getInvoice } from '../../store/actions'
import { getDateDisplay } from '../../util/convertToHumanReadableTime'
import { Positions } from '../../util/positions'
import logo from '../images/joker_logo.jpg'


const { Header } = Layout
const { Title } = Typography

const fontSize = '14px'

const PrintSale = ({ status, error, auth, sale, getInvoice }) => {
  const navigate = useNavigate()
  const componentRef = useRef()
  const [items, setItems] = useState([])

  const param = useParams()

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // })

  const handlePrint = () => {
    // Select the component or DOM element to be converted
    const element = componentRef.current;
  
    // Convert the element to a canvas
    html2canvas(element).then((canvas) => {
      // Create a new PDF document
      const pdf = new jsPDF();
  
      const printerWidth = 210; // Assuming the printer width in mm (e.g., A4 page width)
      const printerPixelWidth = printerWidth * 3.7795; // Convert mm to pixels at 96dpi
      const scaleFactor = printerPixelWidth / canvas.width;
      const imgWidth = printerWidth; 
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      // Add the canvas image to the PDF document
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);
  
      // Save the PDF document
      pdf.save(`Sale Invoice`);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getInvoice(param.id)
    }

    fetchData()
    return () => fetchData()
  }, [getInvoice, param.id])

  useEffect(() => {
    const transfromItems = sale.sale.items?.map((item) => {
      return {
        ...item,
        key: item.id,
      }
    })
    setItems(transfromItems)
  }, [sale.sale])

  useEffect(() => {
    error.message !== null && message.error(error.message)

    return () => error.message
  }, [error.message])

  const columns = [
    {
      title: 'ပစ္စည်းအမည်',
      dataIndex: 'stock',
      render: (stock) =>
        `${stock?.item?.item_name?.name}(${stock?.item.size})(${stock?.item.color})`,
      width: '50%',
    },
    {
      title: 'တစ်ခုဈေး',
      dataIndex: 'price',
      width: '20%',
    },
    {
      title: 'အရေအတွက်',
      dataIndex: 'quantity',
      width: '10%',
    },
    {
      title: 'စုစုပေါင်း',
      dataIndex: 'subtotal',
      width: '20%',
    },
  ]

  const discountAmount = sale.sale.total * (sale.sale.discount / 100)
  const taxAmount = sale.sale.total * (sale.sale.tax / 100)

  const handleDashboard = () => {
    navigate('/admin/create-sales')
  }

  const { position } = auth.user

  return (
    <Spin spinning={status.loading}>
      <Layout className='print-sale'>
        <Header style={{ backgroundColor: 'var(--primary-color)' }}>
          <Title
            style={{
              color: 'var(--white-color)',
              textAlign: 'center',
              marginTop: '13px',
            }}
            level={3}
          >
            အရောင်း‌ဘောင်ချာ print ထုတ်ခြင်း
          </Title>
        </Header>
        <Row>
          <Col span={10}></Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            <Button
              style={{
                backgroundColor: 'var(--primary-color)',
                color: 'var(--white-color)',
              }}
              size="large"
              onClick={handlePrint}
            >
              <PrinterOutlined />
              Print
            </Button>
          </Col>
          <Col span={2}>
            {(position === Positions.MANAGER ||
              position === Positions.CASHIER) && (
              <Button
                style={{
                  backgroundColor: 'var(--primary-color)',
                  color: 'var(--white-color)',
                }}
                size="large"
                onClick={handleDashboard}
              >
                အရောင်းစာမျက်နှာ
              </Button>
            )}
          </Col>
          <Col span={10}></Col>
        </Row>
        <div
          style={{ margin: '30px 0px', overflow: 'hidden' }}
          ref={componentRef}
          className='print-sale'
        >
          <Row>
            <Col className="gutter-row" span={6}></Col>
            <Col
              className="gutter-row"
              span={12}
              style={{ textAlign: 'center', marginBottom: '20px' }}
            >
              <Image width={100} src={logo} />
              <Title level={5}>{sale.sale?.shop?.name}</Title>
            </Col>
            <Col className="gutter-row" span={6}></Col>
          </Row>

          <Row>
            <Col span={1}></Col>
            <Col span={22}>
              <Row>
                <Col span={4}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>ပင်ရင်း</Title>
                </Col>
                <Col span={1}></Col>
                <Col span={19}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                    အမှတ်(၁၁၂/အေ) လမ်း၈၀နှင့် ၃၁လမ်းထောင့်၊ မန္တလေးမြို့။ ဖုန်း
                    - ၀၂-၄၀၇၃၉၀၂
                  </Title>
                </Col>
              </Row>
              {/* <Row>
                <Col span={4}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}></Title>
                </Col>
                <Col span={1}></Col>
                <Col span={19}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}></Title>
                </Col>
              </Row> */}

              <Row>
                <Col span={4}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>ဆိုင်(၂)</Title>
                </Col>
                <Col span={1}></Col>
                <Col span={19}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                    အမှတ်(၁၈၃) ၃၄လမ်း ၈၂-၈၃လမ်းကြား၊မန္တလေးမြို့။ ဖုန်း -
                    ၀၂-၄၀၇၃၉၀၂
                  </Title>
                </Col>
              </Row>
              {/* <Row>
                <Col span={4}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}></Title>
                </Col>
                <Col span={1}></Col>
                <Col span={19}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}></Title>
                </Col>
              </Row> */}
            </Col>
            <Col span={1}></Col>
          </Row>
          <Row>
            <Col span={1}></Col>
            <Col span={22}>
              <Divider
                style={{
                  height: '1px',
                  borderWidth: '0',
                  color: 'black',
                  backgroundColor: 'black',
                  margin: '5px 0px',
                }}
              />
            </Col>
            <Col span={1}></Col>
          </Row>
          <Row>
            <Col span={1}></Col>
            <Col span={11}>
              <Row>
                <Col span={9}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>Date:</Title>
                </Col>
                <Col span={1}></Col>
                <Col span={14}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                    {getDateDisplay(sale.sale.created_at)}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={9}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>Customer Phone:</Title>
                </Col>
                <Col span={1}></Col>
                <Col span={14}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                    {sale.sale.customer_phone_no}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={9}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>Customer Name:</Title>
                </Col>
                <Col span={1}></Col>
                <Col span={14}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                    {sale.sale.customer_name}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col span={11}>
              <Row>
                <Col span={9}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>Voucher Code:</Title>
                </Col>
                <Col span={1}></Col>
                <Col span={14}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                    {sale.sale.voucher_code}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={9}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>Payment:</Title>
                </Col>
                <Col span={1}></Col>
                <Col span={14}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                    {sale.sale.payment_method}
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={9}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>Sale Agent:</Title>
                </Col>
                <Col span={1}></Col>
                <Col span={14}>
                  <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                    {sale.sale?.user?.name}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col span={1}></Col>
          </Row>
          <Table
            bordered
            columns={columns}
            dataSource={items}
            pagination={false}
            size="small"
          />
          <Row gutter={[16, 16]}>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>စုစုပေါင်း</Title>
            </Col>
            <Col span={2}></Col>
            <Col span={5} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>{sale.sale.total}</Title>
            </Col>
            <Col span={1}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>လျော့ဈေး</Title>
            </Col>
            <Col span={3} style={{ textAlign: 'left' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                {sale.sale.discount}%
              </Title>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>{discountAmount}</Title>
            </Col>
            <Col span={1}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>အခွန်</Title>
            </Col>
            <Col span={3} style={{ textAlign: 'left' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>{sale.sale.tax}%</Title>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>{taxAmount}</Title>
            </Col>
            <Col span={1}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>ပွိုင့်</Title>
            </Col>
            <Col span={3} style={{ textAlign: 'left' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>{sale.sale.points}</Title>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                {sale.sale.point_amount}
              </Title>
            </Col>
            <Col span={1}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                ပေးချေရမည့်စုစုပေါင်း
              </Title>
            </Col>
            <Col span={2}></Col>
            <Col span={5} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>
                {sale.sale.final_total}
              </Title>
            </Col>
            <Col span={1}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>ပေးငွေ</Title>
            </Col>
            <Col span={2}></Col>
            <Col span={5} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>{sale.sale.paid}</Title>
            </Col>
            <Col span={1}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>ပေးရန်ကျန်ငွေ</Title>
            </Col>
            <Col span={2}></Col>
            <Col span={5} style={{ textAlign: 'right' }}>
              <Title style={{ fontSize: fontSize,fontWeight:"normal" }}>{sale.sale.credit}</Title>
            </Col>
            <Col span={1}></Col>
          </Row>
        </div>
      </Layout>
    </Spin>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  auth: store.auth,
  sale: store.sale,
})

export default connect(mapStateToProps, {
  getInvoice,
})(PrintSale)
