import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Table,
  DatePicker,
  message,
  Spin,
  Select,
  Button,
  Row,
  Col,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { FilterOutlined } from "@ant-design/icons";
import queryString from "query-string";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { getInvoiceReport, getShops } from "../../store/actions";
import { useLocation } from "react-router-dom";
import { Positions } from "../../util/positions";

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

const ProfitReport = ({
  status,
  error,
  sale,
  shop,
  auth,
  getInvoiceReport,
  getShops,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shopId, setShopId] = useState(undefined);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      await getInvoiceReport(queryString.parse(location.search));
      await getShops();
    };

    fetchData();
    return () => fetchData();
  }, [getInvoiceReport, getShops, location.search]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  const handleFilter = async () => {
    if (startDate !== "" && endDate !== "" && shopId !== undefined) {
      await getInvoiceReport(
        queryString.parse(
          `?start_date=${startDate}&end_date=${endDate}&shop=${shopId}`
        )
      );
    } else if (startDate !== "" && endDate !== "") {
      await getInvoiceReport(
        queryString.parse(`?start_date=${startDate}&end_date=${endDate}`)
      );
    } else if (shopId !== undefined) {
      await getInvoiceReport(queryString.parse(`?shop=${shopId}`));
    } else {
      message.warn("ကျေးဇူးပြု၍ filter လုပ်ရန် data ထည့်ပါ။");
    }
  };

  const columns = [
    {
      title: "ပစ္စည်းရောင်းရငွေစုစုပေါင်း",
      dataIndex: "sale_total",
    },
    {
      title: "ပစ္စည်းအရင်းစုစုပေါင်း",
      dataIndex: "buy_total",
      render: (buyTotal) => <span style={{ color: "red" }}>{buyTotal}</span>,
    },
    {
      title: "အထွေထွေကုန်ကျငွေစုစုပေါင်း",
      dataIndex: "expense_total",
      render: (expenseTotal) => (
        <span style={{ color: "red" }}>{expenseTotal}</span>
      ),
    },
    {
      title: "အမြတ်စုစုပေါင်း",
      dataIndex: "profit",
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Title level={3}>Profit Report</Title>
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 8 }}>
              <RangePicker
                onChange={(val) => {
                  if (val !== null) {
                    setStartDate(dayjs(val[0]).format("YYYY-MM-DD"));
                    setEndDate(dayjs(val[1]).format("YYYY-MM-DD"));
                  } else {
                    setStartDate("");
                    setEndDate("");
                  }
                }}
              />
            </Col>
            <Col xl={{ span: 8 }}>
              {auth.user.position === Positions.OWNER && (
                <Select
                  showSearch
                  placeholder="ကျေးဇူးပြု၍ ဆိုင်အမည်ရွေးပါ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  size="large"
                  style={{ borderRadius: "10px" }}
                  onChange={(value) => setShopId(value)}
                >
                  {shop.shops.map((shop) => (
                    <Option value={shop.id} key={shop.id}>
                      {shop.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col span={{ span: 4 }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={handleFilter}
              >
                <FilterOutlined />
                Filter
              </Button>
            </Col>
            <Col span={{ span: 4 }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={async () =>
                  await getInvoiceReport(queryString.parse(location.search))
                }
              >
                Refresh
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            dataSource={sale.saleReport}
            pagination={false}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  sale: store.sale,
  auth: store.auth,
  shop: store.shop,
});

export default connect(mapStateToProps, {
  getInvoiceReport,
  getShops,
})(ProfitReport);
