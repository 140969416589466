import React, { useEffect } from "react";
import { Typography, Space, Row, Col, Table, message, Spin } from "antd";
import Layout from "antd/lib/layout/layout";
import { connect } from "react-redux";
import { getYearlyExpenses } from "../../store/actions";
import { ExportToExcel } from "../excel/ExportToExcel";

const { Title } = Typography;

const ShowYearlyExpenses = ({ status, error, expense, getYearlyExpenses }) => {
  useEffect(() => {
    getYearlyExpenses();

    return () => getYearlyExpenses();
  }, [getYearlyExpenses]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  const columns = [
    {
      title: "နှစ်",
      dataIndex: "year",
    },
    {
      title: "ပမာဏ",
      dataIndex: "amount",
    },
  ];

  const exportYearlyExpense = expense.yearly.map((yearly) => {
    return {
      year: yearly.year,
      amount: yearly.amount,
    };
  });

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 21 }}>
              <Title level={3}>နှစ်အလိုက် ကုန်ကျစရိတ်စာရင်း</Title>
            </Col>
            <Col xl={{ span: 3 }}>
              <ExportToExcel
                apiData={exportYearlyExpense}
                fileName="နှစ်အလိုက်-ကုန်ကျစရိတ်များ"
              />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={expense.yearly}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  expense: store.expense,
});

export default connect(mapStateToProps, { getYearlyExpenses })(
  ShowYearlyExpenses
);
