import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Button,
  Table,
  message,
  Popconfirm,
  Spin,
  DatePicker,
  Select,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import dayjs from "dayjs";
import { getExpenses, deleteExpense, getShops } from "../../store/actions";
import { successDeleteMessage } from "../../util/messages";
import { getReadableDateDisplay } from "../../util/convertToHumanReadableTime";
import { Positions } from "../../util/positions";
import { ExportToExcel } from "../excel/ExportToExcel";

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

const ShowExpenses = ({
  status,
  error,
  auth,
  expense,
  shop,
  getExpenses,
  deleteExpense,
  getShops,
}) => {
  const [filterExpenses, setFilterExpenses] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      await getExpenses(queryString.parse(location.search));
      await getShops();
    };

    fetchData();

    return () => fetchData();
  }, [getExpenses, getShops, location.search]);

  useEffect(() => {
    setFilterExpenses(expense.expenses)
  },[expense.expenses])

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(successDeleteMessage);
    }

    return () => status.success;
  }, [status.success]);

  const handleDelete = async (id) => {
    await deleteExpense(id);
  };

  const handleFilter = (value) => {
    if (value === undefined) {
      setFilterExpenses(expense.expenses);
    } else {
      const filterByShop = expense.expenses.filter(
        (expense) => Number(expense.shop_id) === value
      );
      setFilterExpenses(filterByShop);
    }
  };

  const { position } = auth.user;
  let columns = [];

  if (position === Positions.MANAGER) {
    columns = [
      {
        title: "ရက်စွဲ",
        dataIndex: "created_at",
        render: (createdAt) => getReadableDateDisplay(createdAt),
      },
      {
        title: "ကုန်ကျစရိတ်အမည်",
        dataIndex: "name",
      },
      {
        title: "ပမာဏ",
        dataIndex: "amount",
      },
      {
        title: "ဆိုင်",
        dataIndex: "shop",
        render: (shop) => shop.name,
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (id) => (
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => navigate(`/admin/edit-expenses/${id}`)}
            >
              Edit
            </Button>
            <Popconfirm
              title="ဖျက်မှာ သေချာပြီလား"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(id)}
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
  } else {
    columns = [
      {
        title: "ရက်စွဲ",
        dataIndex: "created_at",
        render: (createdAt) => getReadableDateDisplay(createdAt),
      },
      {
        title: "ကုန်ကျစရိတ်အမည်",
        dataIndex: "name",
      },
      {
        title: "ပမာဏ",
        dataIndex: "amount",
      },
      {
        title: "ဆိုင်",
        dataIndex: "shop",
        render: (shop) => shop.name,
      },
    ];
  }

  const exportExpense = filterExpenses.map((expense) => {
    return {
      id: expense.id,
      date: getReadableDateDisplay(expense.created_at),
      shop: expense.shop.name,
      name: expense.name,
      amount: expense.amount,
    };
  });

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 15 }}>
              <Title level={3}>ကုန်ကျစရိတ်စာရင်း</Title>
            </Col>
            <Col xl={{ span: 6 }}>
              {(position === Positions.MANAGER ||
                position === Positions.STAFF) && (
                <Button
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white-color)",
                    borderRadius: "5px",
                  }}
                  size="middle"
                  onClick={() => navigate("/admin/create-expenses")}
                >
                  <PlusSquareOutlined />
                  New
                </Button>
              )}
              {position === Positions.OWNER && (
                <Select
                  showSearch
                  placeholder="ကျေးဇူးပြု၍ ဆိုင်အမည်ရွေးပါ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  size="large"
                  style={{ borderRadius: "10px" }}
                  onChange={(value) => handleFilter(value)}
                >
                  {shop.shops.map((shop) => (
                    <Option value={shop.id} key={shop.id}>
                      {shop.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col xl={{ span: 3 }}>
              <ExportToExcel
                apiData={exportExpense}
                fileName="ကုန်ကျစရိတ်များ"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 24 }}>
              <RangePicker
                onChange={(val) => {
                  window.location = `/admin/show-expenses?start_date=${dayjs(
                    val[0]
                  ).format("YYYY-MM-DD")}&end_date=${dayjs(val[1]).format(
                    "YYYY-MM-DD"
                  )}`;
                }}
              />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={filterExpenses}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  auth: store.auth,
  expense: store.expense,
  shop: store.shop,
});

export default connect(mapStateToProps, {
  getExpenses,
  deleteExpense,
  getShops,
})(ShowExpenses);
