import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Button,
  Table,
  DatePicker,
  Spin,
  message,
  Popconfirm,
  Select,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { FilterOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import queryString from "query-string";
import dayjs from "dayjs";
import { getInvoices, deleteInvoice, getShops } from "../../store/actions";
import { getReadableDateDisplay } from "../../util/convertToHumanReadableTime";
import { successDeleteMessage } from "../../util/messages";
import { useLocation, useNavigate } from "react-router-dom";
import { Positions } from "../../util/positions";
import { ExportToExcel } from "../excel/ExportToExcel";

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

const InvoiceReport = ({
  status,
  error,
  auth,
  sale,
  shop,
  getInvoices,
  deleteInvoice,
  getShops,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shopId, setShopId] = useState(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      await getInvoices(queryString.parse(location.search));
      await getShops();
    };
    fetchData();
    return () => {
      fetchData();
    };
  }, [getInvoices, getShops, location.search]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(successDeleteMessage);
    }

    return () => status.success;
  }, [status.success]);

  const handleDelete = async (id) => {
    await deleteInvoice(id);
  };

  const handleFilter = async () => {
    if (startDate !== "" && endDate !== "" && shopId !== undefined) {
      await getInvoices(
        queryString.parse(
          `?start_date=${startDate}&end_date=${endDate}&shop=${shopId}`
        )
      );
    } else if (startDate !== "" && endDate !== "") {
      await getInvoices(
        queryString.parse(`?start_date=${startDate}&end_date=${endDate}`)
      );
    } else if (shopId !== undefined) {
      await getInvoices(queryString.parse(`?shop=${shopId}`));
    } else {
      message.warn("ကျေးဇူးပြု၍ filter လုပ်ရန် data ထည့်ပါ။");
    }
  };

  const { position } = auth.user;
  let columns = [];

  if (position === Positions.MANAGER) {
    columns = [
      {
        title: "ရက်စွဲ",
        dataIndex: "created_at",
        render: (createdAt) => getReadableDateDisplay(createdAt),
      },
      {
        title: "ဘောင်ချာကုတ်",
        dataIndex: "voucher_code",
      },
      {
        title: "ဝယ်သူအမည်",
        dataIndex: "customer_name",
      },
      {
        title: "ပေးချေရမည့်ပမာဏ",
        dataIndex: "final_total",
        sorter: (a, b) => a.total - b.total,
      },
      {
        title: "ပွိုင့်ပမာဏ",
        dataIndex: "point_amount",
      },
      {
        title: "ပေးချေခဲ့သောပမာဏ",
        dataIndex: "paid",
        sorter: (a, b) => a.total - b.total,
      },
      {
        title: "ပေးရန်ကျန်ငွေ",
        dataIndex: "credit",
        sorter: (a, b) => a.total - b.total,
      },
      {
        title: "ဆိုင်",
        dataIndex: "shop",
        render: (shop) => shop.name,
      },
      {
        title: "ရောင်းသူအမည်",
        dataIndex: "user",
        render: (user) => user.name,
      },
      {
        title: "အကြွေးဆပ်ခြင်း",
        dataIndex: "id",
        render: (id) => (
          <Button
            type="primary"
            style={{ backgroundColor: "#ad6800", borderColor: "#ad6800" }}
            onClick={() => navigate(`/admin/create-sale-credits/${id}`)}
          >
            အကြွေးပေးရန်
          </Button>
        ),
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (id) => (
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => navigate(`/admin/print-sale/${id}`)}
            >
              Detail
            </Button>
            <Popconfirm
              title="ဖျက်မှာ သေချာပြီလား"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(id)}
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
  } else {
    columns = [
      {
        title: "ရက်စွဲ",
        dataIndex: "created_at",
        render: (createdAt) => getReadableDateDisplay(createdAt),
      },
      {
        title: "ဘောင်ချာကုတ်",
        dataIndex: "voucher_code",
      },
      {
        title: "ဝယ်သူအမည်",
        dataIndex: "customer_name",
      },
      {
        title: "ပေးချေရမည့်ပမာဏ",
        dataIndex: "final_total",
        sorter: (a, b) => a.total - b.total,
      },
      {
        title: "ပွိုင့်ပမာဏ",
        dataIndex: "point_amount",
      },
      {
        title: "ပေးချေခဲ့သောပမာဏ",
        dataIndex: "paid",
        sorter: (a, b) => a.total - b.total,
      },
      {
        title: "ပေးရန်ကျန်ငွေ",
        dataIndex: "credit",
        sorter: (a, b) => a.total - b.total,
      },
      {
        title: "ဆိုင်",
        dataIndex: "shop",
        render: (shop) => shop.name,
      },
      {
        title: "ရောင်းသူအမည်",
        dataIndex: "user",
        render: (user) => user.name,
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (id) => (
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => navigate(`/admin/print-sale/${id}`)}
            >
              Detail
            </Button>
          </Space>
        ),
      },
    ];
  }

  const exportedVouchers = sale.sales.map((data) => {
    return {
      "ရက်စွဲ":data.created_at,
      "ဘောင်ချာကုတ်": data.voucher_code,
      "ဝယ်သူအမည်":data.customer_name,
      "ပေးချေရမည့်ပမာဏ":data.final_total,
      "ပွိုင့်ပမာဏ":data.point_amount,
      "ပေးချေခဲ့သောပမာဏ":data.paid,
      "ပေးရန်ကျန်ငွေ":data.credit,
      "ဆိုင်":data.shop.name,
      "ရောင်းသူအမည်":data.user.name
    };
  });

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Title level={3}>ဘောင်ချာအရောင်းမှတ်တမ်းစာမျက်နှာ</Title>

          <Row gutter={[16, 16]}>
            <Col xl={{ span: 8 }}>
              <RangePicker
                onChange={(val) => {
                  if (val !== null) {
                    setStartDate(dayjs(val[0]).format("YYYY-MM-DD"));
                    setEndDate(dayjs(val[1]).format("YYYY-MM-DD"));
                  } else {
                    setStartDate("");
                    setEndDate("");
                  }
                }}
              />
            </Col>
            <Col xl={{ span: 8 }}>
              {position === Positions.OWNER && (
                <Select
                  showSearch
                  placeholder="ကျေးဇူးပြု၍ ဆိုင်အမည်ရွေးပါ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  size="large"
                  style={{ borderRadius: "10px" }}
                  onChange={(value) => setShopId(value)}
                >
                  {shop.shops.map((shop) => (
                    <Option value={shop.id} key={shop.id}>
                      {shop.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col xl={{ span: 3 }}>
              <ExportToExcel apiData={exportedVouchers} fileName="ဘောင်ချာအရောင်းမှတ်တမ်များ" />
            </Col>
            <Col span={{ span: 4 }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={handleFilter}
              >
                <FilterOutlined />
                Filter
              </Button>
            </Col>
            <Col span={{ span: 4 }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={async () =>
                  await getInvoices(queryString.parse(location.search))
                }
              >
                Refresh
              </Button>
            </Col>
            <Col>
              <h2>Total = {sale.total}</h2>
            </Col>
          </Row>

          <Table
            bordered
            columns={columns}
            dataSource={sale.sales}
            pagination={{ defaultPageSize: 10 }}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  shop: store.shop,
  auth: store.auth,
  sale: store.sale,
});

export default connect(mapStateToProps, {
  getInvoices,
  deleteInvoice,
  getShops,
})(InvoiceReport);
