import React, { useEffect } from "react";
import { Typography, Space, Row, Col, Table, message, Spin } from "antd";
import Layout from "antd/lib/layout/layout";
import { connect } from "react-redux";
import { getIncomeItemTransfers } from "../../store/actions";
import { getReadableDateDisplay } from "../../util/convertToHumanReadableTime";
import { ExportToExcel } from "../excel/ExportToExcel";

const { Title } = Typography;

const ShowIncomeItemTransfers = ({
  status,
  error,
  itemTransfer,
  getIncomeItemTransfers,
}) => {
  useEffect(() => {
    getIncomeItemTransfers();

    return () => getIncomeItemTransfers();
  }, [getIncomeItemTransfers]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  const columns = [
    {
      title: "ရက်စွဲ",
      dataIndex: "created_at",
      render: (createdAt) => getReadableDateDisplay(createdAt),
    },
    {
      title: "ဆိုင်အမည်(မှ)",
      dataIndex: "shop",
      render: (shop) => shop?.name,
    },
    {
      title: "ပစ္စည်းအမည်",
      dataIndex: "stock",
      render: (stock) =>
        `${stock.item?.item_name?.name}(${stock.item?.size})(${stock.item?.color})`,
    },
    {
      title: "ပစ္စည်းကုတ်",
      dataIndex: "stock",
      render: (stock) => stock?.item?.code,
    },
    {
      title: "အရေအတွက်",
      dataIndex: "quantity",
    },
  ];

  const exportInvoiceItemTransfer = itemTransfer.incomeItemTransfers.map(
    (itemTransfer) => {
      return {
        id: itemTransfer.id,
        date: getReadableDateDisplay(itemTransfer.created_at),
        to_shop: itemTransfer.to_shop.name,
        from_shop: itemTransfer.shop.name,
        item_code: itemTransfer.stock.item.code,
        item_name: `${itemTransfer.stock.item?.item_name?.name}(${itemTransfer.stock.item?.size})(${itemTransfer.stock.item?.color})`,
        quantity: itemTransfer.quantity,
      };
    }
  );

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 18 }}>
              <Title level={3}>လွှဲပြောင်းဝင်လာသောပစ္စည်းများ</Title>
            </Col>
            <Col xl={{ span: 6 }}>
              <ExportToExcel
                apiData={exportInvoiceItemTransfer}
                fileName="လွဲပြောင်းဝင်လာသောပစ္စည်းများ"
              />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            dataSource={itemTransfer.incomeItemTransfers}
            pagination={{ defaultPageSize: 10 }}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  itemTransfer: store.itemTransfer,
});

export default connect(mapStateToProps, {
  getIncomeItemTransfers,
})(ShowIncomeItemTransfers);
