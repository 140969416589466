import React, { useEffect } from "react";
import {
  Form,
  Typography,
  Space,
  Button,
  message,
  Spin,
  InputNumber,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { successEditMessage } from "../../util/messages";
import { getPoint, editPoint } from "../../store/actions";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const EditPoints = ({ status, error, point, getPoint, editPoint }) => {
  const param = useParams();
  const [form] = Form.useForm();

  useEffect(() => {
    getPoint(param.id);
  }, [getPoint, param.id]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(successEditMessage);
    }

    return () => status.success;
  }, [form, status.success]);

  useEffect(() => {
    form.setFieldsValue({ point: point.point.point });
    form.setFieldsValue({ price: point.point.price });
  }, [form, point.point]);

  const onFinish = async (values) => {
    await editPoint(param.id, values);
  };

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Title style={{ textAlign: "center" }} level={3}>
            ကုန်ကျစရိတ်အမည် ပြင်ဆင်ခြင်း စာမျက်နှာ
          </Title>
          <Form
            labelCol={{
              xl: {
                span: 3,
              },
            }}
            wrapperCol={{
              xl: {
                span: 24,
              },
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              name="point"
              label="ပွိုင့်"
              rules={[
                {
                  required: true,
                  message: "ကျေးဇူးပြု၍ ပွိုင့်ထည့်ပါ",
                },
              ]}
            >
              <InputNumber
                placeholder="ပွိုင့်ထည့်ပါ"
                prefix={<EditOutlined />}
                style={{ borderRadius: "10px", width: "100%" }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="price"
              label="တန်ဖိုး"
              rules={[
                {
                  required: true,
                  message: "ကျေးဇူးပြု၍ တန်ဖိုးထည့်ပါ",
                },
              ]}
            >
              <InputNumber
                placeholder="တန်ဖိုးထည့်ပါ"
                prefix={<EditOutlined />}
                style={{ borderRadius: "10px", width: "100%" }}
                size="large"
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "10px",
                }}
                size="large"
                htmlType="submit"
              >
                <SaveOutlined />
                သိမ်းမည်
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  point: store.point,
});

export default connect(mapStateToProps, { getPoint, editPoint })(EditPoints);
