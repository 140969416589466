import {
  DELETE_EXPENSE,
  MONTHLY_EXPENSES,
  SHOW_EXPENSE,
  SHOW_EXPENSES,
  YEARLY_EXPENSES,
} from "../type";

const initialState = {
  expenses: [],
  expense: {},
  monthly: [],
  yearly: [],
};

const expense = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_EXPENSES:
      return {
        ...state,
        expenses: action.payload,
      };
    case MONTHLY_EXPENSES:
      return {
        ...state,
        monthly: action.payload,
      };
    case YEARLY_EXPENSES:
      return {
        ...state,
        yearly: action.payload,
      };
    case SHOW_EXPENSE:
      return {
        ...state,
        expense: action.payload,
      };
    case DELETE_EXPENSE:
      return {
        ...state,
        expenses: state.expenses.filter(
          (expense) => expense.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default expense;
