import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Button,
  Table,
  message,
  Popconfirm,
  Spin,
  Select,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  getItemTransfers,
  deleteItemTransfer,
  getShops,
} from "../../store/actions";
import { getReadableDateDisplay } from "../../util/convertToHumanReadableTime";
import { successDeleteMessage } from "../../util/messages";
import { Positions } from "../../util/positions";
import { ExportToExcel } from "../excel/ExportToExcel";

const { Title } = Typography;
const { Option } = Select;

const ShowItemTransfers = ({
  status,
  error,
  auth,
  itemTransfer,
  shop,
  getItemTransfers,
  deleteItemTransfer,
  getShops,
}) => {
  const [filterItemTransfers, setFilterItemTransfers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await getItemTransfers();
      await getShops();
    };
    fetchData();

    return () => fetchData();
  }, [getItemTransfers, getShops]);

  useEffect(() => {
    setFilterItemTransfers(itemTransfer.itemTransfers);
  }, [itemTransfer.itemTransfers]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      message.success(successDeleteMessage);
    }

    return () => status.success;
  }, [status.success]);

  const handleDelete = async (id) => {
    await deleteItemTransfer(id);
  };

  const handleFilter = (value) => {
    if (value === undefined) {
      setFilterItemTransfers(itemTransfer.itemTransfers);
    } else {
      const filterByShop = itemTransfer.itemTransfers.filter(
        (itemTransfer) => Number(itemTransfer.shop_id) === value
      );
      setFilterItemTransfers(filterByShop);
    }
  };

  const { position } = auth.user;
  let columns = [];

  if (position === Positions.MANAGER) {
    columns = [
      {
        title: "ရက်စွဲ",
        dataIndex: "created_at",
        render: (createdAt) => getReadableDateDisplay(createdAt),
      },
      {
        title: "ဆိုင်အမည်(သို့)",
        dataIndex: "to_shop",
        render: (toShop) => toShop?.name,
      },
      {
        title: "ပစ္စည်းအမည်",
        dataIndex: "stock",
        render: (stock) =>
          `${stock.item?.item_name?.name}(${stock.item?.size})(${stock.item?.color})`,
      },
      {
        title: "ပစ္စည်းကုတ်",
        dataIndex: "stock",
        render: (stock) => stock?.item?.code,
      },
      {
        title: "အရေအတွက်",
        dataIndex: "quantity",
      },
      {
        title: "Actions",
        dataIndex: "id",
        render: (id) => (
          <Space direction="horizontal">
            <Popconfirm
              title="ဖျက်မှာ သေချာပြီလား"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleDelete(id)}
            >
              <Button type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
  } else {
    columns = [
      {
        title: "ရက်စွဲ",
        dataIndex: "created_at",
        render: (createdAt) => getReadableDateDisplay(createdAt),
      },
      {
        title: "ဆိုင်အမည်(မှ)",
        dataIndex: "shop",
        render: (shop) => shop?.name,
      },
      {
        title: "ဆိုင်အမည်(သို့)",
        dataIndex: "to_shop",
        render: (toShop) => toShop?.name,
      },
      {
        title: "ပစ္စည်းအမည်",
        dataIndex: "stock",
        render: (stock) =>
          `${stock.item?.item_name?.name}(${stock.item?.size})(${stock.item?.color})`,
      },
      {
        title: "ပစ္စည်းကုတ်",
        dataIndex: "stock",
        render: (stock) => stock?.item?.code,
      },
      {
        title: "အရေအတွက်",
        dataIndex: "quantity",
      },
    ];
  }

  const exportItemTransfer = filterItemTransfers.map((itemTransfer) => {
    return {
      id: itemTransfer.id,
      date: getReadableDateDisplay(itemTransfer.created_at),
      from_shop: itemTransfer.shop.name,
      to_shop: itemTransfer.to_shop.name,
      item_code: itemTransfer.stock.item.code,
      item_name: `${itemTransfer.stock.item?.item_name?.name}(${itemTransfer.stock.item?.size})(${itemTransfer.stock.item?.color})`,
      quantity: itemTransfer.quantity,
    };
  });

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 15 }}>
              <Title level={3}>လွဲပြောင်းခဲ့သောပစ္စည်းများ</Title>
            </Col>
            <Col xl={{ span: 6 }}>
              {(position === Positions.MANAGER ||
                position === Positions.STAFF) && (
                <Button
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white-color)",
                    borderRadius: "5px",
                  }}
                  size="middle"
                  onClick={() => navigate("/admin/create-item-transfers")}
                >
                  <PlusSquareOutlined />
                  New
                </Button>
              )}
              {position === Positions.OWNER && (
                <Select
                  showSearch
                  placeholder="ကျေးဇူးပြု၍ ဆိုင်အမည်ရွေးပါ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  size="large"
                  style={{ borderRadius: "10px" }}
                  onChange={(value) => handleFilter(value)}
                >
                  {shop.shops.map((shop) => (
                    <Option value={shop.id} key={shop.id}>
                      {shop.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col xl={{ span: 3 }}>
              <ExportToExcel
                apiData={exportItemTransfer}
                fileName="လွဲပြောင်းသောပစ္စည်းများ"
              />
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            dataSource={filterItemTransfers}
            pagination={{ defaultPageSize: 10 }}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  auth: store.auth,
  itemTransfer: store.itemTransfer,
  shop: store.shop,
});

export default connect(mapStateToProps, {
  getItemTransfers,
  deleteItemTransfer,
  getShops,
})(ShowItemTransfers);
