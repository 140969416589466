import React, { useEffect } from "react";
import { Form, Input, Typography, Space, Button, message, Spin } from "antd";
import Layout from "antd/lib/layout/layout";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { changePassword, logout } from "../store/actions";
import { successChangePassword } from "../util/messages";

const { Title } = Typography;

const ChangePassword = ({ status, error, changePassword, logout }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      form.resetFields();
      message.success(successChangePassword);
      logout();
    }

    return () => status.success;
  }, [form, status.success, logout]);

  const onFinish = async (values) => {
    await changePassword(values);
  };

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Title style={{ textAlign: "center" }} level={3}>
            Password ပြောင်းလဲခြင်း စာမျက်နှာ
          </Title>
          <Form
            labelCol={{
              xl: {
                span: 3,
              },
            }}
            wrapperCol={{
              xl: {
                span: 24,
              },
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              name="current_password"
              label="လက်ရှိ Password"
              rules={[
                {
                  required: true,
                  message: "ကျေးဇူးပြု၍ လက်ရှိ Passwordထည့်ပါ",
                },
              ]}
            >
              <Input.Password
                placeholder="လက်ရှိ Passwordထည့်ပါ"
                prefix={<EditOutlined />}
                style={{ borderRadius: "10px" }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="new_password"
              label="အသစ် Password"
              rules={[
                {
                  required: true,
                  message: "ကျေးဇူးပြု၍ အသစ် Passwordထည့်ပါ",
                },
              ]}
            >
              <Input.Password
                placeholder="အသစ် Passwordထည့်ပါ"
                prefix={<EditOutlined />}
                style={{ borderRadius: "10px" }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="new_password_confirmation"
              label="ထပ်ရိုက်ပါ"
              rules={[
                {
                  required: true,
                  message: "ကျေးဇူးပြု၍ ထပ်ရိုက်ပါ",
                },
              ]}
            >
              <Input.Password
                placeholder="ထပ်ရိုက်ပါ"
                prefix={<EditOutlined />}
                style={{ borderRadius: "10px" }}
                size="large"
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "10px",
                }}
                size="large"
                htmlType="submit"
              >
                <SaveOutlined />
                သိမ်းမည်
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
});

export default connect(mapStateToProps, { changePassword, logout })(ChangePassword);
