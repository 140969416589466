import React, { useEffect, useState } from "react";
import {
  Space,
  Typography,
  Form,
  Button,
  InputNumber,
  Select,
  Table,
  message,
  Spin,
} from "antd";
import Layout from "antd/lib/layout/layout";
import {
  EditOutlined,
  SaveOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { getItems, createStock } from "../../store/actions";
import { successCreateMessage } from "../../util/messages";

const { Title } = Typography;
const { Option } = Select;

const CreateStocks = ({ status, error, item, getItems, createStock }) => {
  const [stocks, setStocks] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      await getItems();
    };

    fetchData();
    return () => fetchData();
  }, [getItems]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  useEffect(() => {
    if (status.success) {
      setStocks([]);
      message.success(successCreateMessage);
    }

    return () => status.success;
  }, [form, status.success]);

  const onFinish = (values) => {
    const existStock = stocks.findIndex(
      (stock) => stock.item_id === values.item_id
    );
    if (existStock === -1) {
      const findItem = item.items.find((item) => item.id === values.item_id);

      setStocks([
        ...stocks,
        {
          ...values,
          item: findItem,
          key: stocks.length,
        },
      ]);
      form.resetFields();
    } else {
      message.warning("ထည့်ထားပြီးသော ပစ္စည်းဖြစ်နေပါသည်။");
    }
  };

  const handleDelete = (record) => {
    const filterStocks = stocks.filter((stock) => stock !== record);
    const transformStocks = filterStocks.map((stock, index) => {
      return {
        ...stock,
        key: index + 1,
      };
    });
    setStocks(transformStocks);
  };

  const handleSave = async () => {
    if (stocks.length === 0) {
      message.error("ကျေးဇူးပြု၍ stock ထည့်ပါ");
    } else {
      await createStock({ stocks });
    }
  };

  const columns = [
    {
      title: "ပစ္စည်းအမည်",
      dataIndex: "item",
      render: (item) => `${item.item_name?.name}(${item.size})(${item.color})`,
    },
    {
      title: "အရေအတွက်",
      dataIndex: "quantity",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Button type="primary" danger onClick={() => handleDelete(record)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Title style={{ textAlign: "center" }} level={3}>
            Stock စာရင်းသွင်းရန်
          </Title>
          <Form
            labelCol={{
              xl: {
                span: 3,
              },
            }}
            wrapperCol={{
              xl: {
                span: 24,
              },
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              name="item_id"
              label="ပစ္စည်းအမည်"
              rules={[
                {
                  required: true,
                  message: "ကျေးဇူးပြု၍ ပစ္စည်းအမည်ထည့်ပါ",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="ကျေးဇူးပြု၍ ပစ္စည်းအမည်ရွေးပါ"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear={true}
                size="large"
                style={{ borderRadius: "10px" }}
              >
                {item.items.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {`${item.item_name.name}(${item.size})(${item.color})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="quantity"
              label="အရေအတွက်"
              rules={[
                {
                  required: true,
                  message: "ကျေးဇူးပြု၍ အရေအတွက်ထည့်ပါ",
                },
              ]}
            >
              <InputNumber
                placeholder="အရေအတွက်ထည့်ပါ"
                prefix={<EditOutlined />}
                style={{ borderRadius: "10px", width: "100%" }}
                size="large"
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <Button
                style={{
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white-color)",
                  borderRadius: "10px",
                }}
                size="large"
                htmlType="submit"
              >
                <PlusSquareOutlined />
                အသစ်ထည့်မည်
              </Button>
            </Form.Item>
          </Form>
          <Table
            bordered
            columns={columns}
            dataSource={stocks}
            pagination={false}
          />
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "right" }}
          >
            <Button
              style={{
                backgroundColor: "var(--primary-color)",
                color: "var(--white-color)",
                borderRadius: "10px",
              }}
              size="large"
              onClick={handleSave}
            >
              <SaveOutlined />
              သိမ်းမည်
            </Button>
          </Space>
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  item: store.item,
});

export default connect(mapStateToProps, {
  getItems,
  createStock,
})(CreateStocks);
