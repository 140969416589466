import { DELETE_POINT, SHOW_POINT, SHOW_POINTS } from "../type";

const initialState = {
  points: [],
  point: {},
};

const point = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_POINTS:
      return {
        ...state,
        points: action.payload,
      };
    case SHOW_POINT:
      return {
        ...state,
        point: action.payload,
      };
    case DELETE_POINT:
      return {
        ...state,
        points: state.points.filter((point) => point.id !== action.payload),
      };
    default:
      return state;
  }
};

export default point;
