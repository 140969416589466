import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Table,
  DatePicker,
  message,
  Spin,
  Row,
  Col,
  Button,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { FilterOutlined } from "@ant-design/icons";
import queryString from "query-string";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { getPurchaseReport, getShops } from "../../store/actions";
import { useLocation, useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;
const { Title } = Typography;

const PurchaseReport = ({
  status,
  error,
  purchase,
  getPurchaseReport,
  getShops,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await getPurchaseReport(queryString.parse(location.search));
      await getShops();
    };
    fetchData();
    return () => fetchData();
  }, [getPurchaseReport, getShops, location.search]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  const handleFilter = async () => {
    if (startDate !== "" && endDate !== "") {
      await getPurchaseReport(
        queryString.parse(`?start_date=${startDate}&end_date=${endDate}`)
      );
    } else {
      message.warn("ကျေးဇူးပြု၍ filter လုပ်ရန် data ထည့်ပါ။");
    }
  };

  const columns = [
    {
      title: "ကုန်သည်အမည်",
      dataIndex: "merchant",
      render: (merchant) => merchant.name,
    },
    {
      title: "စုစုပေါင်း",
      dataIndex: "whole_total",
      sorter: (a, b) => a.whole_total - b.whole_total,
    },
    {
      title: "ပေးပြီးငွေစုစုပေါင်း",
      dataIndex: "paid",
      sorter: (a, b) => a.paid - b.paid,
    },
    {
      title: "ပေးရန်ကျန်ငွေစုစုပေါင်း",
      dataIndex: "credit",
      sorter: (a, b) => a.credit - b.credit,
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Title level={3}>အဝယ်မှတ်တမ်းစာမျက်နှာ</Title>

          <Row gutter={[16, 16]}>
            <Col xl={{ span: 8 }}>
              <RangePicker
                onChange={(val) => {
                  if (val !== null) {
                    setStartDate(dayjs(val[0]).format("YYYY-MM-DD"));
                    setEndDate(dayjs(val[1]).format("YYYY-MM-DD"));
                  } else {
                    setStartDate("");
                    setEndDate("");
                  }
                }}
              />
            </Col>
            <Col xl={{ span: 4 }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={handleFilter}
              >
                <FilterOutlined />
                Filter
              </Button>
            </Col>
            <Col xl={{ span: 4 }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={async () =>
                  await getPurchaseReport(queryString.parse(location.search))
                }
              >
                Refresh
              </Button>
            </Col>
            <Col xl={{ span: 8 }} style={{ textAlign: "right" }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={() => navigate("/admin/best-purchase-report")}
              >
                အဝယ်များသောပစ္စည်းစာရင်း
              </Button>
            </Col>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={purchase.purchaseReport}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  purchase: store.purchase,
});

export default connect(mapStateToProps, {
  getPurchaseReport,
  getShops,
})(PurchaseReport);
