import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Table,
  DatePicker,
  message,
  Spin,
  Button,
  Col,
  Row,
  Select,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { FilterOutlined } from "@ant-design/icons";
import queryString from "query-string";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { getInvoiceItems, getShops } from "../../store/actions";
import { getReadableDateDisplay } from "../../util/convertToHumanReadableTime";
import { useLocation, useNavigate } from "react-router-dom";
import { Positions } from "../../util/positions";

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

const SaleItemReport = ({
  status,
  error,
  shop,
  item,
  auth,
  getInvoiceItems,
  getShops,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shopId, setShopId] = useState(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      await getInvoiceItems(queryString.parse(location.search));
      await getShops();
    };
    fetchData();

    return () => fetchData();
  }, [getInvoiceItems, getShops, location.search]);

  useEffect(() => {
    error.message !== null && message.error(error.message);

    return () => error.message;
  }, [error.message]);

  const handleFilter = async () => {
    if (startDate !== "" && endDate !== "" && shopId !== undefined) {
      await getInvoiceItems(
        queryString.parse(
          `?start_date=${startDate}&end_date=${endDate}&shop=${shopId}`
        )
      );
    } else if (startDate !== "" && endDate !== "") {
      await getInvoiceItems(
        queryString.parse(`?start_date=${startDate}&end_date=${endDate}`)
      );
    } else if (shopId !== undefined) {
      await getInvoiceItems(queryString.parse(`?shop=${shopId}`));
    } else {
      message.warn("ကျေးဇူးပြု၍ filter လုပ်ရန် data ထည့်ပါ။");
    }
  };

  const columns = [
    {
      title: "စဥ်",
      dataIndex: "id",
    },
    {
      title: "ရက်စွဲ",
      dataIndex: "created_at",
      render: (createdAt) => getReadableDateDisplay(createdAt),
    },
    {
      title: "ပစ္စည်းအမည်",
      dataIndex: "stock",
      render: (stock) =>
        `${stock?.item?.item_name?.name}(${stock?.item.size})(${stock?.item.color})`,
    },
    {
      title: "တစ်ခုဈေး",
      dataIndex: "price",
    },
    {
      title: "အရေအတွက်",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "စုစုပေါင်း",
      dataIndex: "subtotal",
    },
    {
      title: "ဆိုင်",
      dataIndex: "shop",
      render: (shop) => shop.name,
    },
  ];

  return (
    <Spin spinning={status.loading}>
      <Layout style={{ margin: "20px" }}>
        <Space direction="vertical" size="middle">
          <Title level={3}>ပစ္စည်းအရောင်းမှတ်တမ်းစာမျက်နှာ</Title>
          <Row gutter={[16, 16]}>
            <Col xl={{ span: 8 }}>
              <RangePicker
                onChange={(val) => {
                  if (val !== null) {
                    setStartDate(dayjs(val[0]).format("YYYY-MM-DD"));
                    setEndDate(dayjs(val[1]).format("YYYY-MM-DD"));
                  } else {
                    setStartDate("");
                    setEndDate("");
                  }
                }}
              />
            </Col>
            <Col xl={{ span: 8 }}>
              {auth.user.position === Positions.OWNER && (
                <Select
                  showSearch
                  placeholder="ကျေးဇူးပြု၍ ဆိုင်အမည်ရွေးပါ"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear={true}
                  size="large"
                  style={{ borderRadius: "10px" }}
                  onChange={(value) => setShopId(value)}
                >
                  {shop.shops.map((shop) => (
                    <Option value={shop.id} key={shop.id}>
                      {shop.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Col>
            <Col span={{ span: 4 }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={handleFilter}
              >
                <FilterOutlined />
                Filter
              </Button>
            </Col>
            <Col span={{ span: 4 }}>
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  borderRadius: "5px",
                }}
                size="middle"
                onClick={async () =>
                  await getInvoiceItems(queryString.parse(location.search))
                }
              >
                Refresh
              </Button>
            </Col>
          </Row>
          {(auth.user.position === Positions.OWNER ||
            auth.user.position === Positions.MANAGER) && (
            <Row gutter={[16, 16]}>
              <Col xl={{ span: 24 }}>
                <Button
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white-color)",
                    borderRadius: "5px",
                  }}
                  size="middle"
                  onClick={() => navigate("/admin/best-item-report")}
                >
                  ရောင်းအားကောင်းသောပစ္စည်းစာရင်း
                </Button>
              </Col>
            </Row>
          )}
          <Table
            bordered
            columns={columns}
            pagination={{ defaultPageSize: 10 }}
            dataSource={item.invoiceItems}
          />
        </Space>
      </Layout>
    </Spin>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  error: store.error,
  auth: store.auth,
  item: store.item,
  shop: store.shop,
});

export default connect(mapStateToProps, {
  getInvoiceItems,
  getShops,
})(SaleItemReport);
